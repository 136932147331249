@charset "UTF-8";
.btn-save {
    float: right;
    border-radius: 15px !important;
    padding-left: 56px !important;
    padding-right: 56px !important;
}

.btn-edit-portfolio-composition {
    border-radius: 10px !important;
    display: inline-block;
    margin-top: 13px;
    padding-left: 30px !important;
    padding-right: 30px !important;
}

.card-portfolio-composition {
    font-size: 13px !important;
}
.header-link {
    font-size: 14px !important; 
}
.box-class .ant-checkbox-group {
    border: 1px solid #038fde;
    padding: 10px;
    height: 100px;
    overflow-y: scroll;
    scrollbar-width: auto;
}

.margin-right-5 {
    margin-right: 5px;
}

.card-comment-investor {
    padding: 20px;
}

.search-account {
    margin-left: 44px;
    padding-left: 40px !important;
    padding-right: 40px !important;
}

.search-account-col {
    float: right
}

.search-account-final {
    margin-left: 45px !important;
}

.eye {
    font-size: 25px;
    font-weight: bold;
}

.row-option {
    margin-bottom: 8px;
}

.float-right {
    float: right;
}

.float-left {
    float: left;
}

.title-account-nav {
    padding-left: 10px;
    padding-top: 15px;
    height: 23px;
    font-size: 23px;
}

.table-tab-account {
    border: 1px solid black;
    border-collapse: collapse;
}

.table-tab-account .thead {
    background-color: #a3a3ea;
}

.tdata {
    text-align: center;
}

.icon-info {
    font-size: 23px;
}

.btn-delete {
    color: #fff;
    background-color: #C30052;
}

.under-line {
    text-decoration: underline;
    font-size: 23px;
}

.under-line.font-20 {
    text-decoration: underline;
    font-size: 18px;
}

.content-modal {
    margin-top: 28px;
}

.title-confirm-modal {
    color: red;
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
}

.content-confirm {
    font-size: 23px !important;
}

.table-infor-acc-cls-sr {
    width: 60%;
}

.table-infor-acc-cls-sr>th,
td {
    padding: 15px;
}

.card-info-acc-cls-sr .ant-card.ant-card-bordered {
    border: none !important;
}

.card-info-acc-cls-sr .ant-card-head-title {
    padding-bottom: 5px;
    border-bottom: 1px solid #A0A3BD;
}

.card-info-acc-cls-sr .ant-card-head {
    display: inline-block;
}

.card-investor-transaction .ant-card-body {
    padding: 0px;
}

.cart-investor {
    padding-top: 45px;
}

.modal-portfolio-composition .ant-modal-title {
    font-size: 18px;
}

.modal-portfolio-composition .ant-modal-header {
    border: none;
}

.portfolio-composition {
    padding: 0px 20px 20px 20px;
}

.cart-investor .ant-card-head {
    text-decoration: underline;
    padding-left: 15px !important;
}

.btn-add-division {
    padding-top: 0px !important;
    display: inline-block;
    padding-bottom: 0px !important;
    border-radius: 15px;
    border: solid 1px #038fde;
}

.expanded-option {
    border: solid 1px #038fde;
    display: inline-block;
    margin-bottom: 8px;
}

.radio {
    margin-bottom: 10px;
}

.radio .ant-radio-wrapper {
    font-size: 18px;
}

.expanded-option.right {
    margin-left: 8px;
}

th.ant-table-cell.action {
    padding-right: 50px !important;
}

.components-table-demo-nested thead>tr>th {
    background-color: #4F4F4F;
    color: #FFFFFF;
}

.components-table-demo-nested.table-edit-class-name thead>tr>th {
    background-color: #EFF0F7 !important;
    color: #000000 !important;
}

.status-active {
    display: inline-block;
    margin-left: 7px;
}

.card-comment-investor .ant-card-head-title {
    text-decoration: underline;
}

.ant-card-head {
    border-bottom: none !important;
}

.btn-back-investor {
    border-radius: 15px;
    background-color: #346CA6;
    color: #ffff;
}

.btn-back {
    display: inline-block;
    padding-left: 28px;
    padding-right: 28px;
}

.tranfer-in {
    margin-left: 30px;
}

.label-left .ant-form-item-label {
    text-align: left;
}

.month_end_flag .ant-form-item-control-input {
    border: 1px solid #d9d9d9;
    padding: 3px;
    border-radius: 5px;
}

.card-redemption .ant-card-head-title {
    padding-left: 35px;
}

.override {
    float: right;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    padding: 3px;
}

.liquidity-title {
    font-size: 16px;
    margin-bottom: 30px;
}

.btn-save-caculate {
    float: right;
    border-radius: 15px !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
}

.comment-transaction .ant-col.ant-col-24.ant-form-item-control {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.card-account .ant-card-head-title {
    text-decoration: underline;
}

.acc-viewer-title {
    text-decoration: underline;
}

.tabs-acc-view .ant-tabs-tab {
    /* background: #D9DBE9 !important;
    border-color: #404040 !important; */
    padding-top: 10px;
}


/* .tabs-acc-view .ant-tabs-tab.ant-tabs-tab-active {
    background: #ffff !important;
} */

.ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
    background: #038fde !important;
    color: #fff !important;
}

.clear {
    border-radius: 15px !important;
    padding-left: 50px !important;
    padding-right: 50px !important;
    color: #346CA6;
    border: 2px solid #346CA6
}

.ant-row.ant-form-item.porfolio .ant-form-item-label>label {
    font-size: 18px !important;
}

.font-18 {
    font-size: 18px;
}
.font-sz-13 {
    font-size: 13px !important;
}

/* .ant-btn.ant-btn-dangerous {
    background-color: #C30052 !important;
    color: #FCFCFC !important;
} */

.btn-dangerous {
    background-color: #C30052 !important;
    color: #FCFCFC !important;
}

.modal-confirm-cancel .ant-btn {
    background-color: #D9DBE9;
    color: #14142B;
    display: inline-block;
}

.components-table-custom .ant-table-small .ant-table-thead>tr>th {
    background-color: #4F4F4F;
    color: #FFFFFF;
    /* text-align: center; */
}

.components-table-custom .ant-table-middle .ant-table-thead>tr>th {
    background-color: #4F4F4F;
    color: #FFFFFF;
    /* text-align: center; */
}

.components-table-custom.investor.hf .ant-table-body {
    max-height: none !important;
    height: calc(100vh - 375px);
    overflow: auto !important;
}

.components-table-custom.investor.acct .ant-table-body {
    max-height: none !important;
    height: calc(100vh - 290px);
    overflow: auto !important;
}

.components-table-custom.investor.organ .ant-table-body {
    max-height: none !important;
    height: calc(100vh - 394px);
    overflow: auto !important;
}

.components-table-custom.investor.return .ant-table-body {
    max-height: none !important;
    height: calc(100vh - 366px);
    overflow: auto !important;
}

.components-table-custom.investor.hfNav .ant-table-body {
    max-height: none !important;
    height: calc(100vh - 410px);
    overflow: auto !important;
    font-size: 11px;
}

.components-table-custom.portTxn .ant-table-body {
    max-height: none !important;
    height: calc(100vh - 228px);
    overflow: auto !important;
}

.components-table-custom.investor.acctNav .ant-table-body {
    max-height: none !important;
    height: calc(100vh - 424px);
    overflow: auto !important;
}

.components-table-custom.investor.invTxn .ant-table-body {
    max-height: none !important;
    height: calc(100vh - 332px);
    overflow: auto !important;
}

.components-table-custom.investor.dataViewHf .ant-table-body {
    max-height: none !important;
    height: calc(100vh - 356px);
    overflow: auto !important;
}

.components-table-custom.investor.dataViewAcct .ant-table-body {
    max-height: none !important;
    height: calc(100vh - 335px);
    overflow: auto !important;
}

.components-table-custom.investor.perHF .ant-table-body {
    max-height: none !important;
    height: calc(100vh - 580px);
    overflow: auto !important;
}

.components-table-custom.investor.perListHF .ant-table-body {
    max-height: none !important;
    height: calc(100vh - 642px);
    overflow: auto !important;
}

.components-table-custom.investor.acctDC .ant-table-body {
    max-height: none !important;
    height: calc(100vh - 370px);
    overflow: auto !important;
    font-size: 11px;
}

.components-table-custom.investor.hfDC .ant-table-body {
    max-height: none !important;
    height: calc(100vh - 405px);
    overflow: auto !important;
    font-size: 11px;
}

.components-table-custom.investor.portTxnList .ant-table-body {
    max-height: none !important;
    height: calc(100vh - 420px);
    overflow: auto !important;
}
.components-table-custom.investor.portTxnList .ant-table-row-level-0 {
    background-color: #d6e4ff;
}
.components-table-custom.investor.portTxnList .ant-table-row-level-0 .ant-table-cell {
    background-color: #d6e4ff;
}

.btn-action-table-custome {
    margin-bottom: 0px;
    margin-right: 0px;
    /* color: #dbeaf3; */
    border-color: #038fde;
}

.btn-action-table-custome a {
    color: #fff
}

.btn-action-table-custome.ant-btn[disabled] {
    pointer-events: none;
}

.btn-action-table-custome.edit {
    background-color: #24993f;
    color: #fff;
    border-color: #24993f;
}
.btn-action-table-custome.edit:hover {
    background-color: rgb(42, 183, 75);
    border-color: rgb(42, 183, 75);
    color:  #fff;
}
.btn-action-table-custome.edit:active {
    background-color: rgb(42, 183, 75);
    border-color: rgb(42, 183, 75);
    color:  #fff;
}

.btn-action-table-custome.edit.disabled {
    background-color: grey;
    color: #fff;
}

.breadcrumb-header span {
    font-size: 17px;
    color: #262626;
    font-weight: 400;
    line-height: 1.2;
}

.breadcrumb-header.a {
    padding-bottom: 20px;
}

.ant-card {
    margin-bottom: 15px;
}

.ant-card-portTxn {
    margin-bottom: 0px;
}
.ant-table-tbody>tr.ant-table-row:hover>td {
  background: #f5f5f5;
} 
/* .ant-table-tbody>tr>td.ant-table-cell-row-hover */
.components-table-custom.investor.investTxn.hfNav thead>tr>th {
    text-align: left !important;
}

.components-table-custom.investor.hfNav thead>tr>th {
    text-align: left !important;
}

.components-table-custom.investor.hfDC thead>tr>th {
    text-align: left !important;
}

.components-table-custom.investor.investTxn.acctNav thead>tr>th {
    text-align: left !important;
}

.components-table-custom.investor.acctDC thead>tr>th {
    text-align: left !important;
}

.components-table-custom.investor.returnData thead>tr>th {
    text-align: left !important;
}
.components-table-custom.investor.dataView thead>tr>th {
    text-align: left !important;
}
.components-table-custom.investor thead>tr>th.action {
    text-align: center !important;
}
.components-table-custom.investor.table-market-data .ant-table-tbody > tr > td {
    font-size: 13px;
}
.components-table-custom.investor .ant-table-fixed-header thead .ant-table-cell.ant-table-cell-fix-left:hover {
    background-color: #003366;
}

.components-table-custom.investor.dataView .ant-table-thead>tr>th {
    padding: 5px 8px;
}

.components-table-custom.investor.dataView .ant-table-tbody>tr>td {
    padding: 5px 8px;
    font-size: 12.5px;
}
.components-table-custom.investor.dataView.WAR {
    td[rowspan] {
        vertical-align: top;
      }
}
.components-table-custom.investor.investTxn .ant-table-body .ant-table-row-level-0 .ant-table-cell {
    background: unset;
}

.ant-table-tbody>tr.ant-table-row.table-row-warning:hover>td,
.ant-table-tbody>tr.table-row-warning>td.ant-table-cell-row-hover {
    background: #faad14;
}

.components-table-custom.investor.investTxn .ant-table-body .ant-table-row-level-1 .ant-table-cell {
    background: unset;
}


/* .components-table-custom.investor.investTxn .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
    font-size: 10px;
 } */

.components-table-custom.investor.portTxn .ant-table-tbody>tr>td {
    padding: 5px 8px;
}


/* .components-table-custom.investor thead > tr > th.ant-table-cell.action.ant-table-cell-ellipsis {
    text-align: center !important;
} */
.components-table-custom .ant-table-small .ant-table-thead>tr>th {
    background-color: #003366;
    border-bottom: 1px solid white;
}

.components-table-custom-portfolio .ant-table-small .ant-table-thead>tr>th {
    background-color: #003366;
    border-bottom: 1px solid white;
    color: white;
}

.components-table-custom .ant-table-middle .ant-table-thead>tr>th {
    background-color: #003366;
    border-bottom: 1px solid white;
}
.components-table-item-custom .ant-table-small {
    margin-bottom: 15px;
}
.components-table-item-custom .ant-table-small .ant-table-thead>tr>th {
    background-color: yellow;
    border-bottom: 1px solid white;
    padding: 5px 8px;
}
.components-table-item-custom .ant-table-tbody>tr>td {
    padding: 5px 8px;
    font-size: 12.5px;
}
/* .components-table-portfolio tr {
    background-color: aqua;
} */

.components-table .ant-table-content .ant-table-thead>tr>th {
    background-color: white;
    color: #535353;
}

.components-table .ant-table-content .ant-table-row-level-0 .ant-table-cell {
    background-color: white;
}

.components-table.nav .ant-table-tbody>tr>td {
    padding: 4px 8px;
}

.ant-btn.ant-btn-sm.btn-action-table-custome a {
    padding-left: 4px;
}

.component-table-portfolio .ant-table-content .ant-table-row-level-0 {
    background-color: #f0f5ff !important;
}

.component-table-portfolio .ant-table-content .ant-table-row-level-1.rollUp-cell {
    background-color: #f6ffed
}

.hidden {
    display: none;
}

.component-table-portfolio .ant-table-content .ant-table-row-level-0 .ant-table-cell {
    background-color: #f0f5ff;
}

.component-table-portfolio .ant-table-content .ant-table-row-level-1.rollUp-cell .ant-table-cell {
    background-color: #f6ffed ;
}
.component-table-portfolio.nav {
    margin-left: 53px;
}
.component-table-portfolio.nav .ant-table-tbody>tr>td {
    padding: 5px 8px;
}
.component-table-portfolio.nav .ant-table-row-level-0 .ant-table-cell {
    background-color: #f0f5ff !important;
}

.component-table-portfolio.nav .ant-table-thead>tr>th {
    background-color: white;
    color: #535353;
    height: 5px;
    text-align: none !important;
}

.ant-table.ant-table-middle .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
    margin: -12px -8px -12px 11px !important;
}


/* .component-table-portfolio.nav thead>tr>th {
    text-align: none !important;
} */

.gx-content {
    padding: 0px 32px;
}

.currency-error {
    font-size: 13px;
    margin-top: 5px;
}

.disabled-hover .ant-table-column-has-sorters>th {
    background-color: #003366;
}


/* .ant-table-tbody > tr.ant-table-row:hover,:active > tr {
    background: #a0c1ec !important;
} */


/* .ant-table-row.ant-table-row-level-0 {
    background-color: green !important;
} */

.table-row-warning {
    background-color: #faad14;
    font-size: 11px;
}

.table-row-warning .ant-table-cell-fix-left {
    background-color: #faad14;
    font-size: 11px;
}

.table-row-warning .ant-table-body .ant-table-row-level-0:hover .ant-table-cell {
    background-color: #faad14;
}

.table-row-invest-txn {
    background-color: #f0f5ff;
}

.table-row-active {
    background-color: #78bbe2 !important;
    pointer-events: none;
}

.table1 {
    width: 70%;
    margin-left: 15px;
}

@media only screen and (max-width: 1280px) {
    .table1 {
        width: 100%;
    }
}

.action.display-icon:hover {
    margin-left: 10px;
    color: red;
}

.eye-icon.display-icon:hover {
    color: #0368a0;
}

.flex {
    display: flex;
}

.ant-table.ant-table-middle .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
    margin: -12px -8px -12px 45px;
}

.btn-delete-invest {
    border-radius: 5px;
    padding-left: 25px;
    padding-right: 25px;
    color: #fff;
    background-color: #C30052;
}

.fullWidth {
    width: 100%;
}

.description-invest {
    margin-bottom: 20px;
}

.profile-page {
    display: flex;
}

.main-page {
    min-width: 260px;
    max-width: 500px;
    margin-left: 46px;
}

.avt-page {
    flex: 1 1;
    padding-left: 130px;
}

.avt {
    width: 144px;
    height: 144px;
    margin-bottom: 12px;
    overflow: hidden;
}

.cell-total {
    text-align: right;
}
.cell-total-analytical {
    text-align: left;
}

.btn-class-change {
    margin-top: 13px;
}

.text-className {
    margin-right: 10px;
}

.btn-txn {
    border-color: #038fde;
}

.btn-txn a {
    color: white;
}

.edit-icon {
    color: #038fde;
}

.margin-top-20 {
    margin-top: 20px;
}

.margin-top-28 {
    margin-top: 28px;
}

.margin-bottom-20 {
    margin-bottom: 20px;
}

.warning-icon {
    color: crimson;
    font-size: 65px;
    width: 100%;
    margin-bottom: 12px;
}

.delete-text {
    color: red;
}

.margin-left-icon {
    margin-left: 10px;
}


/* .check-box-delete {
    margin-left: 64px;
} */

.margin-bottom-0 {
    margin-bottom: 0px;
}

.margin-bottom-10 {
    margin-bottom: 10px;
}

.status {
    margin-left: 40px;
}

.button-holding-level {
    margin-top: 20px;
}

.margin-top-10 {
    margin-top: 10px;
}

.margin-top-20 {
    margin-top: 20px;
}

.mg-right-3 {
    margin-right: 3px;
}

.mg-right-8 {
    margin-right: 8px;
}

.mg-right-20 {
    margin-right: 20px;
}

.mg-0 {
    margin: 0;
}
.mg-left-18 {
    margin-left: 18px;
}
.mg-left-20 {
    margin-left: 20px;
}
.mg-left-27 {
    margin-left: 27px;
}
.mg-left-10 {
    margin-left: 10px;
}
.mg-left-5 {
    margin-left: 5px;
}
.mg-right-5 {
    margin-right: 5px;
}
.pd-left-10 {
    padding-left: 10px;
}
.pd-bottom-15 {
    padding-bottom: 15px;
}
.pd-right-24 {
    padding-right: 24px;
}
.datePicker-12 {
    width: calc(60% - 12px);
}

.datePicker-70 {
    width: 250px
}

.datePicker-70-29 {
    width: calc(70% - 29px);
}

.width-50 {
    width: 50%;
}

.width-90 {
    width: 90%;
}

.btn-search-ac-nav {
    margin-left: 55px;
}

.input-eqCredit {
    width: 250px;
}

.input-eqCredit .disabled {
    background-color: #FFF;
    color: #535353;
}

.ant-input-number-affix-wrapper-disabled {
    background-color: #FFF;
    color: #535353;
}

.input-acc-nav {
    width: 170px;
}

.content-table-80 {
    width: 80px;
}

.custom-fortfolioManager-actionTable {
    width: 250px;
    display: flex;
    justify-content: space-between;
}

.select-weekly {
    width: 40%;
}

.date-picker-nav {
    width: calc(60% - 8px);
}

.date-picker-nav-allDate {
    width: calc(60% - -41px);
}

.date-picker-class {
    display: inline-block;
    width: calc(23% - 2px);
}

.text-input-disabled {
    background-color: #FFF;
    color: #535353;
}

.btn-add-hf-class {
    justify-content: space-between;
}

.port-text-mmac {
    margin-left: 65px;
}

.class-text-mmac {
    margin-left: 130px;
}

.ant-select.readOnly {
    pointer-events: none;
}

.ant-input-affix-wrapper-readonly.disable-hover {
    pointer-events: none;
}

.ant-input.disable-hover {
    pointer-events: none;
}

.ant-input-number-group-wrapper.disable-hover {
    pointer-events: none;
}

.ant-input-affix-wrapper.disable-hover {
    pointer-events: none;
}

.ant-picker.readOnly {
    pointer-events: none;
}

.ant-switch.readOnly {
    pointer-events: none;
}

.ant-input-number-group-wrapper.readOnly {
    pointer-events: none;
}

.disabledLink {
    pointer-events: none;
}
.edit-modal-profile {
    cursor: pointer;
    position: absolute;
    left: 30%;
}
.postion-center {
    text-align: center;
}

.center-screen {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
    width: 100px;
    height: 100px;
}

.liquidity .disabled {
    background-color: #FFF;
    color: #535353;
}

.padding-top-15 {
    padding-top: 15px;
}

.antd-checkbox-inner .checkbox-mounting {
    background-color: #FFF;
}

.currency-nav>span.before {
    float: left;
}

.custom-footer {
    background: #003366;
    font-size: 9px;
    color: white;
}

.page-header {
    max-height: 72px;
}

.page-header .ant-page-header-heading {
    margin: 0px;
}

.table-extra-dividend {
    position: relative;
    /* display: inline-flex; */
    align-items: center;
    max-width: 100%;
    margin-left: 30%;
}

.isDisabled {
    pointer-events: none;
    opacity: 0.6;
}

.button-calc {
    border-radius: 4px !important;
    margin-left: 30px;
    padding-left: 40px;
    padding-right: 40px;
}
.ant-btn-primary:hover {
    background-color: #40a9ff !important;
    border-color: #40a9ff !important;
    color: #fff !important;
}
.ant-btn-primary:active {
    background-color: #40a9ff !important;
    border-color: #40a9ff !important;
    color: #fff !important;
}
.text-link:hover {
    color: #56aaf0 !important;
}
.ant-switch.check-class-type {
    background-color: #038fde !important;
}
.header-pdf {
    background-color: #003366;
}
.header-pdf h1 {
    color: #FFFFFF;
}
.header-pdf .report-date {
    float: right;
}
.fx_rate_data {
    width: 16%;
    font-size: 12px;
    position: relative;
    top: 7px;
    border-collapse: collapse;
    float: right;
    font-family: 'Segoe UI', sans-serif;
}
.fx_rate_data td {
    padding: 2px;
    border: 1px solid #000000;
    font-family: 'Segoe UI', sans-serif;
}
.fx_rate_data td.title {
    background-color: #C0C0C0;
    font-family: 'Segoe UI', sans-serif;
}
.select-file-frame {
    width: 100%;
    height: 38px;
    border: 1px solid #d9d9d9;
    padding: 6px;
    border-radius: 9px;
    background-color: white;
}
.select-file-text {
    position: relative;
    top: 3px;
    padding: 7px;
    font-size: 15px;
    color: #bfbfbf;
    text-overflow: ellipsis;
}

.title-text-ITEM {
    font-weight: 600;
    font-size: 15px;
}

.item-table-cont {
    min-width: auto;
    padding: 10px;
    margin-right: 10px;
}
.container-vertical {
    display: flex;
    overflow-x: auto; 
    width: 100%;        
    white-space: nowrap;      
}
.bold-text-ybm {
    font-weight: bold;
}
.divider-row td {
    border-top: 3px double #000;
}
.gx-app-register-content {
    padding: 35px 35px 20px;
    width: 100%;
  }
  .gx-app-register-content .ant-input {
    background-color: #f5f5f5;
  }
  .gx-app-register-content .ant-input:focus {
    box-shadow: none;
    border-color: #038fde;
  }
  .gx-app-register-content .gx-btn {
    padding: 6px 35px !important;
    height: auto;
  }
  .gx-app-register-content .ant-form-item-control-wrapper {
    width: 100%;
  }
  .gx-app-register-content .ant-form-item-children {
    display: block;
  }
  @media screen and (max-width: 575px) {
    .gx-app-register-content {
      width: 100%;
      padding: 20px 20px 10px;
    }
  }
@media screen and (min-width: 1286px) {
    .gx-d-lg-none-0 {
        display: none !important;
    }
    .visible-icon::after {
        display: none !important;
    }
}

@media screen and (max-width: 1286px) {
    .visible-icon {
        display: none !important;
    }
}